import React from 'react'

const Header = () => {
  return (
    <div className='head'>
      <div className='head--logo' />
    </div>
  )
}

export default Header
