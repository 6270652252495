import React from 'react'
import style from './style.module.scss'

const NotFound = () => {
  return (
    <div className={style.errors}>
      <div className={style.container}>error</div>
    </div>
  )
}

export default NotFound
